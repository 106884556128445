import { useLogService } from 'admin-portal-shared-services';
import { DDCHoliday } from 'domains/DDCHoliday';
import VendorService from 'services/Vendor/VendorService';
import { setFeatureActivationData } from 'stores/MetaData/MetaDataEvents';

async function execute(): Promise<DDCHoliday[]> {
  const log = useLogService();
  try {
    const result = await VendorService.getFeatureActivationData();
    log.info('queryFeatureActivationData - get feature activation data');

    if (result.hasError) {
      throw new Error();
    }

    setFeatureActivationData(result.data);
  } catch (error) {
    log.error('queryFeatureActivationData error');
  }
  return [];
}

export default { execute };
