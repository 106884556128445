import Api, { Microservices } from 'Api/Api';
import { FeatureActivationData } from 'domains/FeatureActivation';
import { APIResponse } from 'types/APIResponse.interface';

const getFeatureActivationData = async (): Promise<APIResponse<FeatureActivationData>> => {
  const response: APIResponse<FeatureActivationData> = await Api.get({
    microservice: Microservices.flexdeliveryBFFExternal,
    url: `delivery-window/api/v2/Vendor`,
  });

  return response;
};

export default {
  getFeatureActivationData,
};
