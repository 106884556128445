export const referrerKey = 'referrer';

export const getReferrer = (): string => {
  return localStorage.getItem(referrerKey) || '';
};

export const setReferrer = (referrer: string): void => {
  return localStorage.setItem(referrerKey, referrer);
};

export const clearReferrer = (): void => {
  return localStorage.setItem(referrerKey, '');
};
