import { DropdownDDC } from './DDC';

export enum ClientFileStatus {
  queued = 'awaitingProcessing',
  processing = 'processing',
  processed = 'processed',
  error = 'error',
  invalidFile = 'invalidFile',
}

export enum ClientFileType {
  uploadClientsExceptionsFile = 'uploadClientException',
  downloadClients = 'downloadClientBase',
  downloadClientAudit = 'downloadClientAudit',
}

export interface ClientFile {
  fileId: string;
  status: ClientFileStatus;
  email: string;
  processingStartDate: string;
  name?: string;
  type: ClientFileType;
}

export interface ClientUploadFile extends ClientFile {
  errorFileId: string | null;
  createdAt?: string;
  name: string;
  endProcessingDate: string | null;
  linesQuantity: number | null;
  successLinesQuantity: number | null;
  errorLinesQuantity: number | null;
}

export interface DownloadClientBaseFilter {
  ddcs: DropdownDDC[];
}

export interface DownloadRulesBaseFilter {
  ddcs: DropdownDDC[];
}

export interface ClientDownloadFileFilter {
  exception?: boolean;
  hasDayOfWeek?: boolean;
}
