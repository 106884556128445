import { Microservices } from 'Api/Api';
import { COUNTRIES_BY_REGION } from 'constants/CountriesByRegion';
import { Env, isAnyOfEnvs, isLocalHost } from 'utils/envUtils';
import { getSelectedCountry } from 'utils/persistMetaData';

export const getApiHost = (microservice: Microservices, url: string): string | undefined => {
  const urlRequestMicroservices = [
    Microservices.flexdeliveryBFFExternal,
    Microservices.flexdeliveryBFFExternalUpload,
  ];

  const newUrl = url;
  let newMicroservice: string = microservice;

  if (isLocalHost()) {
    return `${newMicroservice.replace('?urlRequest=', '/')}${newUrl}`;
  }

  const selectedCountry = getSelectedCountry();
  if (isAnyOfEnvs([Env.UAT, Env.PROD]) && COUNTRIES_BY_REGION.EU.includes(selectedCountry)) {
    newMicroservice = microservice.replace('api/', 'api/eu/');
  }

  if (urlRequestMicroservices.includes(microservice)) {
    return `${newMicroservice}${newUrl.replace('?', '&')}`;
  }
  return `${newMicroservice}${newUrl}`;
};

export default getApiHost;
