/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticationService } from 'admin-portal-shared-services';
import Axios, { AxiosRequestConfig } from 'axios';
import { APIStatusCodes } from 'constants/APIStatusCodes';
import { getSelectedCountry, getSelectedVendor } from 'utils/persistMetaData';
import { getApiHost } from '../services/host/HostService';

export enum Microservices {
  flexdeliveryBFFExternal = 'api/flexdelivery-bff/api?urlRequest=',
  flexdeliveryBFFExternalUpload = 'api/flexdelivery-bff/api/upload?urlRequest=',
  flexdeliveryBFF = 'api/flexdelivery-bff/api/',
  accounts = 'api/accounts/',
  accountBusiness = 'api/account-business/',
  log = '',
}
export interface IRequest {
  microservice: Microservices;
  url: string;
  body?: any;
  config?: AxiosRequestConfig;
}

const initialAxios = Axios.create({ baseURL: document.location.origin });

const authentication = useAuthenticationService();

authentication.enhancedAxios(initialAxios);

const Api = {
  post: ({ microservice, url, body }: IRequest): Promise<any> =>
    initialAxios
      .post(`${getApiHost(microservice, url)}`, body, {
        headers: {
          country: getSelectedCountry(),
          vendorId: getSelectedVendor(),
          noorders: localStorage.getItem('noOrders') || '',
        },
      })
      .catch((error) => ({
        hasError: true,
        status: error.response?.status?.toString() as APIStatusCodes,
        statusText: error.response?.statusText,
        ...error,
      })),

  delete: ({ microservice, url }: IRequest): Promise<any> =>
    initialAxios
      .delete(`${getApiHost(microservice, url)}`, {
        headers: {
          country: getSelectedCountry(),
          vendorId: getSelectedVendor(),
          noorders: localStorage.getItem('noOrders') || '',
        },
      })
      .catch((error) => ({
        hasError: true,
        status: error.response?.status?.toString() as APIStatusCodes,
        statusText: error.response?.statusText,
        ...error,
      })),

  get: ({ microservice, url, config }: IRequest): Promise<any> =>
    initialAxios
      .get(`${getApiHost(microservice, url)}`, {
        ...config,
        headers: {
          country: getSelectedCountry(),
          vendorId: getSelectedVendor(),
          noorders: localStorage.getItem('noOrders') || '',
        },
      })
      .catch((error) => ({
        hasError: true,
        status: error.response?.status?.toString() as APIStatusCodes,
        statusText: error.response?.statusText,
        ...error,
      })),

  put: ({ microservice, url, body }: IRequest): Promise<any> =>
    initialAxios
      .put(`${getApiHost(microservice, url)}`, body, {
        headers: {
          country: getSelectedCountry(),
          vendorId: getSelectedVendor(),
          noorders: localStorage.getItem('noOrders') || '',
        },
      })
      .catch((error) => ({
        hasError: true,
        status: error.response?.status?.toString() as APIStatusCodes,
        statusText: error.response?.statusText,
        ...error,
      })),

  patch: ({ microservice, url, body }: IRequest): Promise<any> =>
    initialAxios
      .patch(`${getApiHost(microservice, url)}`, body, {
        headers: {
          country: getSelectedCountry(),
          vendorId: getSelectedVendor(),
          noorders: localStorage.getItem('noOrders') || '',
        },
      })
      .catch((error) => ({
        hasError: true,
        status: error.response?.status?.toString() as APIStatusCodes,
        statusText: error.response?.statusText,
        ...error,
      })),
};

export default Api;
