import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { ROUTES } from 'constants/Routes.constants';
import { PagesIDs } from 'constants/SegmentTracking.constants';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AutoblockProgressRoute, DDCsAutoblockRoute } from 'RouterLazyRoutes';
import { trackPageView } from 'tracking/events/pageView/pageViewEvents';

const AutoblockRouter = (): JSX.Element => {
  React.useEffect(() => {
    trackPageView(PagesIDs.CAPACITY_PARAMETERS);
  }, []);

  return (
    <Switch>
      <Route exact path={ROUTES.AUTOBLOCK}>
        <Redirect to={ROUTES.AUTOBLOCK_PROGRESS} />
      </Route>
      <Route exact path={ROUTES.AUTOBLOCK_LIST}>
        <PrivateRoute>
          <DDCsAutoblockRoute />
        </PrivateRoute>
      </Route>
      <Route exact path={ROUTES.AUTOBLOCK_PROGRESS}>
        <PrivateRoute>
          <AutoblockProgressRoute />
        </PrivateRoute>
      </Route>
    </Switch>
  );
};
export default AutoblockRouter;
