/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { APP_ID, APP_NAME, HierarchyGroups } from 'constants/SegmentTracking.constants';
import Client from 'domains/Client';
import DDC from 'domains/DDC';
import { VendorFull } from 'domains/Vendor';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import {
  getSelectedCountry,
  getSelectedVendor,
  getSelectedVendorName,
  getUserEmail,
} from 'utils/persistMetaData';

// #region deprecated events utils

type SelectedCountry = string;

interface TrackingClientData {
  multiple: boolean;
  customer_account_id: string[];
  ddc_id: string[];
}
/**
 * @deprecated This tracking event or function is deprecated in favor of the new default tracking events (pageView, elementClicked, searchPerformed)
 */
export const formatClientData = (clientList: Client[]): TrackingClientData => {
  const multiple = clientList.length > 1;
  const customerAccountId = clientList.map((client) => client.document);
  const ddcId = clientList.map((client) => client.ddcCode);
  return { multiple, customer_account_id: customerAccountId, ddc_id: ddcId };
};

interface TrackingDDCData {
  multiple: boolean;
  ddc_id: string[];
}
/**
 * @deprecated This tracking event or function is deprecated in favor of the new default tracking events (pageView, elementClicked, searchPerformed)
 */
export const formatDDCData = (DDCList: DDC[]): TrackingDDCData => {
  const multiple = DDCList.length > 1;
  const ddcId = DDCList.map((ddc) => ddc.ddcCode);
  return { multiple, ddc_id: ddcId };
};

interface TrackingBasicData {
  selected_country: SelectedCountry;
  screen_name: string;
  vendor_id: string;
  vendor_name: string;
  user_email: string;
}
/**
 * @deprecated This tracking event or function is deprecated in favor of the new default tracking events (pageView, elementClicked, searchPerformed)
 */
export const formatTrackingBasicData = (
  screenName: string,
  country?: string
): TrackingBasicData => {
  const selectedCountry = country || getSelectedCountry();
  const selectedVendorId = getSelectedVendor();
  const selectedVendorName = getSelectedVendorName();
  const userEmail = getUserEmail();

  return {
    selected_country: selectedCountry as SelectedCountry,
    screen_name: screenName,
    vendor_id: selectedVendorId,
    vendor_name: selectedVendorName,
    user_email: userEmail,
  };
};

// #endregion deprecated events utils

// #region new default events utils

interface TrackingDefaultData {
  is_core_event: boolean;
  vendor_country: string;
  vendor_display_name: string;
  vendor_id: string;
  user_email: string | null;
  user_country: string | null;
  user_permission_group: string;
  app_display_name: string;
  app_id: string;
  manufacturer_country: null;
  manufacturer_display_name: null;
  manufacturer_id: null;
  manufacturer_service_model: null;
  manufacturer_tier_name: null;
  vendor_service_model: string | null;
  vendor_tier_name: string | null;
  view_level: string;
}

export const getVendorAdditionalData = (vendorId: string, vendorList?: VendorFull[]) => {
  const selectedVendor = vendorList?.find((vendor) => vendor.id === vendorId);

  return {
    vendor_display_name: selectedVendor ? selectedVendor?.displayName : '',
    vendor_service_model: selectedVendor ? selectedVendor?.serviceModel : null,
    vendor_tier_name: selectedVendor ? selectedVendor?.tier.name : null,
  };
};

export const getHighestAccessGroup = (groups: string[] = []): string => {
  let highestGroup = '';

  HierarchyGroups.every((group) => {
    if (groups.includes(group)) {
      highestGroup = group;
      return false;
    }
    return true;
  });

  return highestGroup;
};

export const getTrackingDefaultData = (): TrackingDefaultData => {
  const MetaData = MetaDataStore.getState();

  const userPermissionGroups = MetaData.groups;

  const countrySelected = MetaData.selectedCountry;

  const userEmail = MetaData.userData.email;
  const userCountry = MetaData.userData.country;

  const vendorId = MetaData.selectedVendor;
  const vendorAdditionalData = getVendorAdditionalData(
    MetaData.selectedVendor,
    MetaData.listVendors
  );

  const staticInfo = {
    app_display_name: APP_NAME,
    app_id: APP_ID,
    is_core_event: false,
    manufacturer_country: null,
    manufacturer_display_name: null,
    manufacturer_id: null,
    manufacturer_service_model: null,
    manufacturer_tier_name: null,
    view_level: 'SINGLE_VENDOR',
  };

  const result = {
    vendor_country: countrySelected,
    vendor_id: vendorId,
    user_email: userEmail,
    user_country: userCountry,
    user_permission_group: getHighestAccessGroup(userPermissionGroups),
    ...vendorAdditionalData,
    ...staticInfo,
  };

  return result;
};

// #endregion new default events utils
