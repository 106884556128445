import { Calendar, Lock, SortDefault, Store } from '@admin-portal-shared-components/icons';
import { Settings } from '@hexa-ui/icons';
import React from 'react';

export const DDCsListIcon = (): JSX.Element => {
  return <Store />;
};

export const DeliveryWindowIcon = (): JSX.Element => {
  return <Calendar />;
};

export const ImportsAndExportsIcon = (): JSX.Element => {
  return <SortDefault />;
};

export const AutoblockIcon = (): JSX.Element => {
  return <Lock />;
};

export const AudienceIcon = (): JSX.Element => {
  return <Settings />;
};
