/* eslint-disable camelcase */
export const APP_NAME = 'Delivery';
export const APP_ID = 'DELIVERY_WINDOW';

export const HierarchyGroups = [
  'LOATDeliveryWindow.BEES Admin',
  'LOATDeliveryWindow.Delivery window Full admin',
  'LOATDeliveryWindow.Date manager',
  'LOATDeliveryWindow.DC manager',
  'LOATDeliveryWindow.Client manager',
  'LOATDeliveryWindow.Flexible window manager',
  'LOATDeliveryWindow.Wholesaler manager',
  'LOATDeliveryWindow.Holiday manager',
  'LOATDeliveryWindow.Delivery capacity availability manager',
  'LOATDeliveryWindow.C&C manager',
  'LOATDeliveryWindow.C&C parameters manager',
  'LOATDeliveryWindow.C&C consumption manager',
  'LOATDeliveryWindow.CSV download manager',
  'LOATDeliveryWindow.Delivery window viewer',
  'LOATDeliveryWindow.C&C viewer',
  'LOATDeliveryWindow.C&C parameters viewer',
  'LOATDeliveryWindow.C&C consumption viewer',
  'LOATDeliveryWindow.Delivery capacity availability viewer',
];

export enum PagesIDs {
  DDCS = 'DDCS',
  DDCS_EDIT = 'DDCS_EDIT',
  DDC_CUSTOM_SETTINGS = 'DDC_CUSTOM_SETTINGS',
  HOLIDAY = 'HOLIDAY',
  DDCS_RULES = 'DDCS_RULES',
  DELIVERY_WINDOW = 'DELIVERY_WINDOW',
  CLIENT_EDIT = 'CLIENT_EDIT',
  CLIENT_RULES = 'CLIENT_RULES',
  UPLOADS_AND_DOWNLOADS = 'UPLOADS_AND_DOWNLOADS',
  UPLOAD_CLIENT_BASE = 'UPLOAD_CLIENT_BASE',
  UPLOAD_RULES_BASE = 'UPLOAD_RULES_BASE',
  CAPACITY_PARAMETERS = 'CAPACITY_PARAMETERS',
  EDIT_CAPACITY_PARAMETERS = 'EDIT_CAPACITY_PARAMETERS',
}

export const PageRouteData = {
  [PagesIDs.DDCS]: {
    page_label: 'DDCs Management',
    page_name: PagesIDs.DDCS,
  },
  [PagesIDs.DDCS_EDIT]: {
    page_label: 'DDCs Management Editing',
    page_name: PagesIDs.DDCS_EDIT,
  },
  [PagesIDs.DDC_CUSTOM_SETTINGS]: {
    page_label: 'DDC Custom Settings Editing',
    page_name: PagesIDs.DDC_CUSTOM_SETTINGS,
  },
  [PagesIDs.HOLIDAY]: {
    page_label: 'Manage Holidays',
    page_name: PagesIDs.HOLIDAY,
  },
  [PagesIDs.DDCS_RULES]: {
    page_label: 'DDCs Exception Rules',
    page_name: PagesIDs.DDCS_RULES,
  },
  [PagesIDs.DELIVERY_WINDOW]: {
    page_label: 'Delivery Windows',
    page_name: PagesIDs.DELIVERY_WINDOW,
  },
  [PagesIDs.CLIENT_EDIT]: {
    page_label: 'Edit POC-level settings',
    page_name: PagesIDs.CLIENT_EDIT,
  },
  [PagesIDs.CLIENT_RULES]: {
    page_label: 'Clients Exception Rules',
    page_name: PagesIDs.CLIENT_RULES,
  },
  [PagesIDs.UPLOADS_AND_DOWNLOADS]: {
    page_label: 'Upload and Download Files',
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
  },
  [PagesIDs.UPLOAD_CLIENT_BASE]: {
    page_label: 'Upload Client Files',
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
  },
  [PagesIDs.UPLOAD_RULES_BASE]: {
    page_label: 'Upload Rules Files',
    page_name: PagesIDs.UPLOAD_RULES_BASE,
  },
  [PagesIDs.CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameters',
    page_name: PagesIDs.CAPACITY_PARAMETERS,
  },
  [PagesIDs.EDIT_CAPACITY_PARAMETERS]: {
    page_label: 'Delivery Capacity Availability Parameter Creation',
    page_name: PagesIDs.EDIT_CAPACITY_PARAMETERS,
  },
};

export enum ElementLocationTypes {
  table = 'TABLE',
  page = 'PAGE',
  floatingMenu = 'FLOATING_MENU',
  pageSection = 'PAGE_SECTION',
  tab = 'TAB',
  modal = 'MODAL',
  deleteModal = 'DELETE_MODAL',
  card = 'CARD',
}

export enum ElementLocationNames {
  ddcList = 'DDC_LIST',
  ddcsEdit = 'DDCS_EDIT',
  holidays = 'HOLIDAYS',
  ddcsRules = 'DDCS_RULES',
  pocList = 'POC_LIST',
  clientEdit = 'CLIENT_EDIT',
  deliveryWindowSection = 'DELIVERY_WINDOW_SECTION',
  resendDeliveryWindows = 'RESEND_DELIVERY_WINDOWS',
  clientRules = 'CLIENT_RULES',
  uploadAndDownloads = 'UPLOAD _AND_DOWNLOADS',
  clientsTab = 'CLIENTS_TAB',
  clientsUploadTab = 'CLIENTS_UPLOAD_TAB',
  uploadClientBase = 'UPLOAD_CLIENT_BASE',
  uploadRulesBase = 'UPLOAD_RULES_BASE',
  rulesTab = 'RULES_TAB',
  rulesUploadTab = 'RULES_UPLOAD_TAB',
  clientsDownloadTab = 'CLIENTS_DOWNLOAD_TAB',
  requestClientsBaseModal = 'REQUEST_CLIENTS_BASE_MODAL',
  rulesDownloadTab = 'RULES_DOWNLOAD_TAB',
  requestRulesBaseModal = 'REQUEST_RULES_BASE_MODAL',
  ddcs = 'DDCS',
  editDeliveryCapacityAvailabilityParameter = 'EDIT_DELIVERY_CAPACITY_AVAILABILITY_PARAMETER',
  capacityParameters = 'CAPACITY_PARAMETERS',
  capacityParameterList = 'CAPACITY_PARAMETER_LIST',
  deleteCapacityParameterModal = 'DELETE_CAPACITY_PARAMETER_MODAL',
  automatedDateCard = 'AUTOMATED_DATE_CARD',
  manualDatesCard = 'MANUAL_DATES_CARD',
  automatedWithoutDates = 'AUTOMATED_WITHOUT_DATES',
}

export enum ElementTypes {
  button = 'BUTTON',
  tab = 'TAB',
  accordion = 'ACCORDION',
  radioButton = 'RADIO_BUTTON',
  link = 'LINK',
}

export enum ElementNames {
  editDdc = 'EDIT_DDC',
  saveDdc = 'SAVE_DDC',
  manageHolidays = 'MANAGE_HOLIDAYS',
  ddcManageExceptionRules = 'DDC_MANAGE_EXCEPTION_RULES',
  viewClickAndCollectConsume = 'VIEW_CLICK_AND_COLLECT_CONSUME',
  saveHoliday = 'SAVE_HOLIDAY',
  saveDdcRules = 'SAVE_DDC_RULES',
  editPoc = 'EDIT_POC',
  loadDeliveryWindowList = 'LOAD_DELIVERY_WINDOW_LIST',
  pocManageExceptionRules = 'POC_MANAGE_EXCEPTION_RULES',
  saveClient = 'SAVE_CLIENT',
  ddcSettings = 'DDC_SETTINGS',
  pocResendWindows = 'POC_RESEND_WINDOWS',
  saveClientRules = 'SAVE_CLIENT_RULES',
  clientsTab = 'CLIENTS_TAB',
  clientsUploadTab = 'CLIENTS_UPLOAD_TAB',
  clientsDownloadTab = 'CLIENTS_DOWNLOAD_TAB',
  clientsUploadStarted = 'CLIENTS_UPLOAD_STARTED',
  clientsFileInstructions = 'CLIENTS_FILE_INSTRUCTIONS',
  clientsDownloadTemplate = 'CLIENTS_DOWNLOAD_TEMPLATE',
  clientsFileUpload = 'CLIENTS_FILE_UPLOAD',
  ddcOrClients = 'DDC_OR_CLIENTS',
  rulesFileInstructions = 'RULES_FILE_INSTRUCTIONS',
  rulesDownloadTemplate = 'RULES_DOWNLOAD_TEMPLATE',
  rulesFileUpload = 'RULES_FILE_UPLOAD',
  rulesTab = 'RULES_TAB',
  rulesUploadTab = 'RULES_UPLOAD_TAB',
  rulesDownloadTab = 'RULES_DOWNLOAD_TAB',
  rulesUploadStarted = 'RULES_UPLOAD_STARTED',
  downloadClientsErrorFile = 'DOWNLOAD_CLIENTS_ERROR_FILE',
  downloadClientsUploadedFile = 'DOWNLOAD_CLIENTS_UPLOADED_FILE',
  downloadRulesErrorFile = 'DOWNLOAD_RULES_ERROR_FILE',
  downloadRulesUploadedFile = 'DOWNLOAD_RULES_UPLOADED_FILE',
  downloadClientsAuditFile = 'DOWNLOAD_CLIENTS_AUDIT_FILE',
  downloadClientsBaseFile = 'DOWNLOAD_CLIENTS_BASE_FILE',
  requestClientsBaseFile = 'REQUEST_CLIENTS_BASE_FILE',
  downloadRulesAuditFile = 'DOWNLOAD_RULES_AUDIT_FILE',
  downloadRulesBaseFile = 'DOWNLOAD_RULES_BASE_FILE',
  requestRulesBaseFile = 'REQUEST_RULES_BASE_FILE',
  audiences = 'AUDIENCES',
  saveCapacityParameter = 'SAVE_CAPACITY_PARAMETER',
  strategyProgressTab = 'STRATEGY_PROGRESS_TAB',
  capacityParameterList = 'CAPACITY_PARAMETER_LIST',
  startCapacityParameterCreation = 'START_CAPACITY_PARAMETER_CREATION',
  deleteCapacityParameter = 'DELETE_CAPACITY_PARAMETER',
  editCapacityParameter = 'EDIT_CAPACITY_PARAMETER',
  confirmDeleteCapacityParameter = 'CONFIRM_DELETE_CAPACITY_PARAMETER',
  cancel = 'CANCEL',
  exportPocsAutomatedDates = 'EXPORT_POCS_AUTOMATED_DATES',
  exportPocsManualDates = 'EXPORT_POCS_MANUAL_DATES',
  exportPocsWithoutDates = 'EXPORT_POCS_WITHOUT_DATES',
  redirectUploadAndDownloads = 'REDIRECT_UPLOAD_AND_DOWNLOADS',
}

type Element = Record<
  ElementNames,
  {
    is_core_event: boolean;
    page_name: PagesIDs;
    element_location_type: ElementLocationTypes;
    element_location_name: ElementLocationNames;
    element_label: string;
    element_name: ElementNames;
    element_type: ElementTypes;
  }
>;

export const elementClicked: Element = {
  [ElementNames.editDdc]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Edit DDC',
    element_name: ElementNames.editDdc,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveDdc]: {
    is_core_event: true,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Save changes',
    element_name: ElementNames.saveDdc,
    element_type: ElementTypes.button,
  },
  [ElementNames.manageHolidays]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Manage Holidays',
    element_name: ElementNames.manageHolidays,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcManageExceptionRules]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'Manage Inclusion/Exclusion Rules',
    element_name: ElementNames.ddcManageExceptionRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.viewClickAndCollectConsume]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.ddcList,
    element_label: 'View Click & Collect consume',
    element_name: ElementNames.viewClickAndCollectConsume,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveHoliday]: {
    is_core_event: true,
    page_name: PagesIDs.HOLIDAY,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.holidays,
    element_label: 'Save Settings',
    element_name: ElementNames.saveHoliday,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveDdcRules]: {
    is_core_event: true,
    page_name: PagesIDs.DDCS_RULES,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsRules,
    element_label: 'Confirm Paramenters',
    element_name: ElementNames.saveDdcRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.editPoc]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Edit',
    element_name: ElementNames.editPoc,
    element_type: ElementTypes.button,
  },
  [ElementNames.loadDeliveryWindowList]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Show delivery window',
    element_name: ElementNames.loadDeliveryWindowList,
    element_type: ElementTypes.button,
  },
  [ElementNames.pocManageExceptionRules]: {
    is_core_event: false,
    page_name: PagesIDs.DELIVERY_WINDOW,
    element_location_type: ElementLocationTypes.floatingMenu,
    element_location_name: ElementLocationNames.pocList,
    element_label: 'Manage rules',
    element_name: ElementNames.pocManageExceptionRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveClient]: {
    is_core_event: true,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.clientEdit,
    element_label: 'Save settings',
    element_name: ElementNames.saveClient,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcSettings]: {
    is_core_event: false,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.pageSection,
    element_location_name: ElementLocationNames.deliveryWindowSection,
    element_label: 'Go to DDC Management',
    element_name: ElementNames.ddcSettings,
    element_type: ElementTypes.button,
  },
  [ElementNames.pocResendWindows]: {
    is_core_event: false,
    page_name: PagesIDs.CLIENT_EDIT,
    element_location_type: ElementLocationTypes.pageSection,
    element_location_name: ElementLocationNames.resendDeliveryWindows,
    element_label: 'Resend window',
    element_name: ElementNames.pocResendWindows,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveClientRules]: {
    is_core_event: true,
    page_name: PagesIDs.CLIENT_RULES,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.clientRules,
    element_label: 'Confirm Paramenters',
    element_name: ElementNames.saveClientRules,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadAndDownloads,
    element_label: 'Clients',
    element_name: ElementNames.clientsTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsUploadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsTab,
    element_label: 'Manage',
    element_name: ElementNames.clientsUploadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsDownloadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsTab,
    element_label: 'Package data requests',
    element_name: ElementNames.clientsDownloadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.clientsUploadStarted]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Upload clients file',
    element_name: ElementNames.clientsUploadStarted,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsFileInstructions]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Review file instructions',
    element_name: ElementNames.clientsFileInstructions,
    element_type: ElementTypes.accordion,
  },
  [ElementNames.clientsDownloadTemplate]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Download template',
    element_name: ElementNames.clientsDownloadTemplate,
    element_type: ElementTypes.button,
  },
  [ElementNames.clientsFileUpload]: {
    is_core_event: true,
    page_name: PagesIDs.UPLOAD_CLIENT_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadClientBase,
    element_label: 'Upload',
    element_name: ElementNames.clientsFileUpload,
    element_type: ElementTypes.button,
  },
  [ElementNames.ddcOrClients]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'DDC or Clients',
    element_name: ElementNames.ddcOrClients,
    element_type: ElementTypes.radioButton,
  },
  [ElementNames.rulesFileInstructions]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Review file instructions',
    element_name: ElementNames.rulesFileInstructions,
    element_type: ElementTypes.accordion,
  },
  [ElementNames.rulesDownloadTemplate]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Download template',
    element_name: ElementNames.rulesDownloadTemplate,
    element_type: ElementTypes.button,
  },
  [ElementNames.rulesFileUpload]: {
    is_core_event: true,
    page_name: PagesIDs.UPLOAD_RULES_BASE,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadRulesBase,
    element_label: 'Upload',
    element_name: ElementNames.rulesFileUpload,
    element_type: ElementTypes.button,
  },
  [ElementNames.rulesTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.uploadAndDownloads,
    element_label: 'Rules',
    element_name: ElementNames.rulesTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesUploadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesTab,
    element_label: 'Manage',
    element_name: ElementNames.rulesUploadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesDownloadTab]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesTab,
    element_label: 'Package data requests',
    element_name: ElementNames.rulesDownloadTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.rulesUploadStarted]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Upload rules file',
    element_name: ElementNames.rulesUploadStarted,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsErrorFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Download errors',
    element_name: ElementNames.downloadClientsErrorFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsUploadedFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.clientsUploadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsUploadedFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesErrorFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Download errors',
    element_name: ElementNames.downloadRulesErrorFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesUploadedFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.rulesUploadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesUploadedFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsAuditFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.clientsDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsAuditFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadClientsBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.clientsDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadClientsBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.requestClientsBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.requestClientsBaseModal,
    element_label: 'Confirm',
    element_name: ElementNames.requestClientsBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesAuditFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.rulesDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesAuditFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.downloadRulesBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.table,
    element_location_name: ElementLocationNames.rulesDownloadTab,
    element_label: 'Download file',
    element_name: ElementNames.downloadRulesBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.requestRulesBaseFile]: {
    is_core_event: false,
    page_name: PagesIDs.UPLOADS_AND_DOWNLOADS,
    element_location_type: ElementLocationTypes.modal,
    element_location_name: ElementLocationNames.requestRulesBaseModal,
    element_label: 'Confirm',
    element_name: ElementNames.requestRulesBaseFile,
    element_type: ElementTypes.button,
  },
  [ElementNames.audiences]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcs,
    element_label: 'Go to Audiences',
    element_name: ElementNames.audiences,
    element_type: ElementTypes.button,
  },
  [ElementNames.saveCapacityParameter]: {
    is_core_event: true,
    page_name: PagesIDs.EDIT_CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.editDeliveryCapacityAvailabilityParameter,
    element_label: 'Save',
    element_name: ElementNames.saveCapacityParameter,
    element_type: ElementTypes.button,
  },
  [ElementNames.strategyProgressTab]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.capacityParameters,
    element_label: 'Strategy Progress',
    element_name: ElementNames.strategyProgressTab,
    element_type: ElementTypes.tab,
  },
  [ElementNames.capacityParameterList]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.capacityParameters,
    element_label: 'Availability parameter list',
    element_name: ElementNames.capacityParameterList,
    element_type: ElementTypes.tab,
  },
  [ElementNames.startCapacityParameterCreation]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Add an Availability Parameter',
    element_name: ElementNames.startCapacityParameterCreation,
    element_type: ElementTypes.tab,
  },
  [ElementNames.deleteCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Remove Availability Parameter',
    element_name: ElementNames.deleteCapacityParameter,
    element_type: ElementTypes.tab,
  },
  [ElementNames.editCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.tab,
    element_location_name: ElementLocationNames.capacityParameterList,
    element_label: 'Edit Availability Parameter',
    element_name: ElementNames.editCapacityParameter,
    element_type: ElementTypes.tab,
  },
  [ElementNames.confirmDeleteCapacityParameter]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.deleteModal,
    element_location_name: ElementLocationNames.deleteCapacityParameterModal,
    element_label: 'Delete',
    element_name: ElementNames.confirmDeleteCapacityParameter,
    element_type: ElementTypes.button,
  },
  [ElementNames.cancel]: {
    is_core_event: false,
    page_name: PagesIDs.CAPACITY_PARAMETERS,
    element_location_type: ElementLocationTypes.deleteModal,
    element_location_name: ElementLocationNames.deleteCapacityParameterModal,
    element_label: 'Go back',
    element_name: ElementNames.cancel,
    element_type: ElementTypes.button,
  },
  [ElementNames.exportPocsAutomatedDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.automatedDateCard,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsAutomatedDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.exportPocsManualDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.manualDatesCard,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsManualDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.exportPocsWithoutDates]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.card,
    element_location_name: ElementLocationNames.automatedWithoutDates,
    element_label: 'Export list',
    element_name: ElementNames.exportPocsWithoutDates,
    element_type: ElementTypes.link,
  },
  [ElementNames.redirectUploadAndDownloads]: {
    is_core_event: false,
    page_name: PagesIDs.DDCS_EDIT,
    element_location_type: ElementLocationTypes.page,
    element_location_name: ElementLocationNames.ddcsEdit,
    element_label: 'Go to file management',
    element_name: ElementNames.redirectUploadAndDownloads,
    element_type: ElementTypes.link,
  },
};
