/* eslint-disable react/no-array-index-key */

import { Select, SelectOption } from '@admin-portal-shared-components/select';
import { Store } from '@material-ui/icons';
import { useUserMetadata } from 'admin-portal-shared-services';
import { useStore } from 'effector-react';
import { formatMessage } from 'i18n/formatters';
import { sortBy } from 'lodash';
import React from 'react';
import { setSelectedVendor } from 'stores/MetaData/MetaDataEvents';
import MetaDataStore from 'stores/MetaData/MetaDataStore';
import getFeatureActivationDataUseCase from 'usecases/Vendor/getFeatureActivationDataUseCase';
import { getSelectedVendor } from 'utils/persistMetaData';
import { VendorSelectContainer } from './VendorSelect.styles';

const VendorSelect = (): JSX.Element => {
  const { selectedCountry, selectedVendor } = useStore(MetaDataStore);

  const supportedVendors = useUserMetadata().data?.vendors || [];
  const isLoadingVendors = useUserMetadata().isLoading;

  const vendorsByCountry = React.useMemo(() => {
    return sortBy(
      supportedVendors.filter((vendor) => vendor.country === selectedCountry),
      'displayName'
    );
  }, [supportedVendors, selectedCountry]);

  const value = React.useMemo(() => {
    return getSelectedVendor(vendorsByCountry);
  }, [vendorsByCountry, selectedVendor]);

  const onSelectVendor = React.useCallback(
    (selectedVendorId: string) => {
      const newSelectedVendor = vendorsByCountry.find((vendor) => vendor.id === selectedVendorId);

      setSelectedVendor({
        vendorId: newSelectedVendor?.id || '',
        vendorName: newSelectedVendor?.displayName || '',
      });
    },

    [vendorsByCountry]
  );

  React.useEffect(() => {
    if (!isLoadingVendors) {
      const isSelectedVendorFromThisCountry = vendorsByCountry.some(
        (vendor) => vendor.id === value
      );
      const [newVendor] = vendorsByCountry;
      if (!isSelectedVendorFromThisCountry && newVendor?.id && newVendor?.displayName) {
        setSelectedVendor({
          vendorId: newVendor.id,
          vendorName: newVendor.displayName,
        });
      }
    }
  }, [isLoadingVendors, vendorsByCountry, value]);

  React.useEffect(() => {
    getFeatureActivationDataUseCase.execute();
  }, [selectedVendor]);

  return (
    <VendorSelectContainer>
      <Select
        name="type"
        size="small"
        icon={<Store />}
        value={value}
        onChange={onSelectVendor}
        placeholder={formatMessage({ id: 'VendorSelect.NO_VENDORS' })}
        disabled={!vendorsByCountry.length}
      >
        {vendorsByCountry?.map((vendor) => (
          <SelectOption value={`${vendor.id}`} key={`${vendor.id}`}>
            {vendor.displayName}
          </SelectOption>
        ))}
      </Select>
    </VendorSelectContainer>
  );
};

export default VendorSelect;
