import DDC, { DropdownDDC } from 'domains/DDC';

export const DDCsListMock: DDC[] = [
  {
    id: '357',
    ddcCode: '357',
    name: 'Rio de Janeiro / São Cristóvão',
    active: true,
    generateDeliveryWindow: true,
    cityCode: 1,
    timeZoneId: 'Greenwich Standard Time',
    clickAndCollectActive: true,
    clickAndCollectFullOrderCapacityActive: false,
    isActive: true,
    isGeneratingDeliveryWindow: true,
  },
  {
    id: '444',
    ddcCode: '444',
    name: 'Rio de Janeiro / Nova Iguaçu',
    active: true,
    generateDeliveryWindow: true,
    cityCode: 1,
    missingFieldsToGenerateDeliveryWindow: ['timeZoneId', 'deliveryDays'],
    clickAndCollectActive: true,
    clickAndCollectFullOrderCapacityActive: true,
    isActive: true,
    isGeneratingDeliveryWindow: false,
  },
  {
    id: '1527',
    ddcCode: '1527',
    name: 'Espírito Santo / Vitória',
    active: true,
    generateDeliveryWindow: true,
    missingFieldsToGenerateDeliveryWindow: ['timeZoneId', 'cityCode'],
    clickAndCollectActive: false,
    clickAndCollectFullOrderCapacityActive: false,
    isActive: true,
  },
  {
    id: '666',
    ddcCode: '666',
    name: null,
    active: true,
    generateDeliveryWindow: true,
    cityCode: 1,
    missingFieldsToGenerateDeliveryWindow: ['deliveryDays'],
    clickAndCollectActive: false,
    clickAndCollectFullOrderCapacityActive: true,
    isActive: true,
  },
  {
    id: '6666',
    ddcCode: '6666',
    name: null,
    active: true,
    generateDeliveryWindow: true,
    missingFieldsToGenerateDeliveryWindow: ['timeZoneId'],
    isActive: true,
  },
  {
    id: 'f779c9ff-06a6-491c-811f-fd00b36bcc8f',
    ddcCode: '100',
    name: 'CDD Londrina',
    active: true,
    generateDeliveryWindow: true,
    missingFieldsToGenerateDeliveryWindow: ['timeZoneId'],
    isActive: true,
  },
];

export const SimpleDDCMock = DDCsListMock[0];

export const DDCWithMissingFieldMock = DDCsListMock[4];

export const DDCWithMultipleMissingFieldsMock = DDCsListMock[2];

export const DDCWithDeliveryDaysMissingField = DDCsListMock[1];
export const DDCWithOnlyDeliveryDaysMissingField = DDCsListMock[3];
export const DDCWithoutDeliveryDaysMissingField = DDCsListMock[2];

export const DDCWithoutMissingFieldMock = DDCsListMock[0];

export const DDCWithoutCityCode = DDCsListMock[2];

export const DDCWithCityCode = DDCsListMock[0];

export const DDCWithNameMock = DDCsListMock[0];
export const DDCWithoutNameMock = DDCsListMock[3];

export const DDCListWithCityCodeMock = [DDCsListMock[0], DDCsListMock[1], DDCsListMock[3]];

export const DDCWithNoCnCFieldsEnabled = DDCsListMock[2];
export const DDCWithBothCnCFieldsEnabled = DDCsListMock[1];
export const DDCWithActiveCnCFieldEnabled = DDCsListMock[0];
export const DDCWithFullOrderCapacityCnCFieldEnabled = DDCsListMock[3];

export const DDCFullyActiveMock = DDCsListMock[0];
export const DDCWithOnlyCNCActiveMock = DDCsListMock[1];
export const DDCFullyInactiveMock = DDCsListMock[2];

export const APIReturnDDCsListMock = {
  ddcs: DDCsListMock,
  page: 1,
  pageSize: 30,
  totalSize: DDCsListMock.length,
};

export const APIReturnDDCsEmptyListMock = {
  ddcs: [],
  page: 1,
  pageSize: 30,
  totalSize: 0,
};

export const CompleteDDCMock = {
  id: '357',
  ddcCode: '357',
  name: 'Rio de Janeiro / São Cristóvão',
  active: true,
  generateDeliveryWindow: true,
  cityCode: 1,
  timeZoneId: 'Greenwich Standard Time',
  deliveryDaysAfterVisitDay: [1, 4, 6],
  windowRange: 14,
  deliveryDaysOfWeek: ['monday', 'friday', 'saturday'],
  daysOfWeekSettings: [
    {
      day: 'monday',
      expirationTime: 594000000000,
      expirationDay: 0,
    },
    {
      day: 'tuesday',
      expirationTime: 594000000000,
      expirationDay: -1,
    },
    {
      day: 'wednesday',
      expirationTime: 594000000000,
      expirationDay: -1,
    },
    {
      day: 'thursday',
      expirationTime: 594000000000,
      expirationDay: -2,
    },
    {
      day: 'friday',
      expirationTime: 594000000000,
      expirationDay: -3,
    },
    {
      day: 'saturday',
      expirationTime: 594000000000,
      expirationDay: -4,
    },
  ],
};

export const DDCWithAudiencesMock = {
  id: '357',
  ddcCode: '357',
  name: 'Rio de Janeiro / São Cristóvão',
  active: true,
  generateDeliveryWindow: true,
  cityCode: 1,
  timeZoneId: 'Greenwich Standard Time',
  deliveryDaysAfterVisitDay: [1, 4, 6],
  windowRange: 14,
  deliveryDaysOfWeek: ['monday', 'friday', 'saturday'],
  daysOfWeekSettings: [
    {
      day: 'monday',
      expirationTime: 594000000000,
      expirationDay: 0,
    },
    {
      day: 'tuesday',
      expirationTime: 594000000000,
      expirationDay: -1,
    },
    {
      day: 'wednesday',
      expirationTime: 594000000000,
      expirationDay: -1,
    },
    {
      day: 'thursday',
      expirationTime: 594000000000,
      expirationDay: -2,
    },
    {
      day: 'friday',
      expirationTime: 594000000000,
      expirationDay: -3,
    },
    {
      day: 'saturday',
      expirationTime: 594000000000,
      expirationDay: -4,
    },
  ],
  ddcAudiences: [
    {
      id: 'mock-id-2',
      audienceExternalId: 'mock-external-id-2',
      audienceName: 'mock audience',
      ddcAudienceExpirationSettings: [
        {
          day: 'friday',
          expirationDay: 1,
          expirationTime: new Date('2030-01-01T12:00:00Z'),
        },
      ],
      deliveryDaysAfterVisitDay: [1, 2],
      deliveryDaysOfWeek: ['friday'],
      holidayDeliveryDay: 0,
      lastUpdateDate: '2024-01-01T12:00:00Z',
      windowRange: 7,
    },
  ],
};

export const DropdownDDCMock: DropdownDDC = {
  ddcCode: 'code',
  name: 'name',
  id: 'mock-id',
};
