import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import { ROUTER_BASENAME } from 'config/constants';
import { permissionScopes } from 'constants/PermissionScopes.constant';
import { ROUTES } from 'constants/Routes.constants';
import { PagesIDs } from 'constants/SegmentTracking.constants';
import React, { useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  ImportsAndExportsClientDownloadRoute,
  ImportsAndExportsClientUploadRoute,
  ImportsAndExportsRuleDownloadRoute,
  ImportsAndExportsRuleUploadRoute,
} from 'RouterLazyRoutes';
import { trackPageView } from 'tracking/events/pageView/pageViewEvents';
import { CheckPermission } from 'utils/checkUserScope/checkUserScope';

const ImportsAndExportsRouter = (): JSX.Element => {
  React.useEffect(() => {
    trackPageView(PagesIDs.UPLOADS_AND_DOWNLOADS);
  }, []);

  const userHasImportsExportsClientUploadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_CLIENT_UPLOAD_READ
  );
  const userHasImportsExportsClientDownloadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_CLIENT_DOWNLOAD_READ
  );
  const userHasImportsExportsRuleUploadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_RULE_UPLOAD_READ
  );
  const userHasImportsExportsRuleDownloadPermission = CheckPermission(
    permissionScopes.IMPORTS_EXPORTS_RULE_DOWNLOAD_READ
  );
  const importsExportsRedirect = useMemo(() => {
    if (userHasImportsExportsClientUploadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/UploadClientsBase`;
    }
    if (userHasImportsExportsClientDownloadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/DownloadClientsBase`;
    }
    if (userHasImportsExportsRuleUploadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/UploadRulesBase`;
    }
    if (userHasImportsExportsRuleDownloadPermission) {
      return `${ROUTER_BASENAME}/ImportsAndExports/DownloadRulesBase`;
    }
    return ROUTER_BASENAME;
  }, [
    userHasImportsExportsClientUploadPermission,
    userHasImportsExportsClientDownloadPermission,
    userHasImportsExportsRuleUploadPermission,
    userHasImportsExportsRuleDownloadPermission,
  ]);

  return (
    <>
      <Switch>
        <Route exact path={ROUTES.IMPORTS_AND_EXPORTS}>
          <Redirect to={importsExportsRedirect} data-testid="imports-exports-base-route" />
        </Route>
        <Route exact path={ROUTES.IMPORTS_AND_EXPORTS_CLIENTS_UPLOAD}>
          <PrivateRoute>
            <ImportsAndExportsClientUploadRoute />
          </PrivateRoute>
        </Route>
        <Route exact path={ROUTES.IMPORTS_AND_EXPORTS_CLIENTS_DOWNLOAD}>
          <PrivateRoute>
            <ImportsAndExportsClientDownloadRoute />
          </PrivateRoute>
        </Route>
        <Route exact path={ROUTES.IMPORTS_AND_EXPORTS_RULES_UPLOAD}>
          <PrivateRoute>
            <ImportsAndExportsRuleUploadRoute />
          </PrivateRoute>
        </Route>
        <Route exact path={ROUTES.IMPORTS_AND_EXPORTS_RULES_DOWNLOAD}>
          <PrivateRoute>
            <ImportsAndExportsRuleDownloadRoute />
          </PrivateRoute>
        </Route>
      </Switch>
    </>
  );
};
export default ImportsAndExportsRouter;
