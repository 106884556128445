import Client, { ClientDocument } from './Client';
import DDC, { DDCCode } from './DDC';
import { RulesSavingErrorFields } from './RulesSaving';

export enum RuleType {
  inclusion = 'inclusion',
  exclusion = 'exclusion',
}

export enum DeliveryWindowType {
  free = 'free',
  flex = 'flex',
}

export enum RuleOwnerType {
  client = 'client',
  ddc = 'ddc',
}

export enum RuleClientType {
  allDdcClients = 'AllDdcClients',
  allDdcFlexibleClients = 'AllDdcFlexibleClients',
  allDdcFreeClients = 'AllDdcFreeClients',
  allDdcClientsWithoutDelivery = 'AllDdcClientsWithoutDelivery',
  ClientOnly = 'ClientOnly',
}

export const RuleClientTypeLabel = {
  AllDdcClients: 'FIELD_TYPE_ALL_DDC_CLIENTS',
  AllDdcFlexibleClients: 'FIELD_TYPE_ALL_DDC_FLEXIBLE_CLIENTS',
  AllDdcFreeClients: 'FIELD_TYPE_ALL_DDC_FREE_CLIENTS',
  AllDdcClientsWithoutDelivery: 'FIELD_TYPE_ALL_DDC_CLIENTES_WITHOUT_DELIVERY',
  ClientOnly: 'FIELD_TYPE_CLIENT_ONLY',
};

export type RuleOwner = DDC | Client;
export type RuleOwnerList = DDC[] | Client[];

export interface RuleExpirationDateTime {
  localExpirationDate?: string;
  localExpirationTime?: string;
}

export interface Rule extends RuleExpirationDateTime {
  id: string;
  type: RuleClientType;
  ruleType: RuleType;
  deliveryDate: string;
  ddcCode: DDCCode;
  ddcName: string | null;
  clientName: string;
  clientDocument: ClientDocument;
  clientCode?: number;
  expirationDate: string | null;
  flexible: boolean;
  minValue?: number;
  addAmount?: number;
  isInserting?: boolean;
  isEditing?: boolean;
  isDeleting?: boolean;
  country?: string;
  errorField?: RulesSavingErrorFields[];
}

export interface RuleDTO {
  id: string;
  inclusionDate: string;
  exclusionDate: string;
  ddcCode: DDCCode;
  clientDocument: ClientDocument;
  expiration: string;
  flexible: boolean;
  minValue?: number;
  addAmount?: number;
}
export interface RulesFormData {
  formType: RuleOwnerType;
  error?: boolean;
  rulesList: Rule[];
}

export interface RuleAddNew {
  deliveryDate: string;
  owners: RuleOwner[];
  ruleType: RuleType;
}

export interface RuleQueryData {
  startDate: Date;
  endDate: Date;
  ddcCodes?: DDCCode[];
  clientDocuments?: string[];
}

export type RuleQueryDataFilter = Omit<RuleQueryData, 'ddcCodes' | 'clientDocuments'>;
